import Swiper, { Navigation, Pagination, Thumbs, Autoplay } from 'swiper';
import addWindowScrollPositionToDocument from './modules/viewport';
import CookieConsent from './modules/cookie-consent';

Swiper.use([Navigation, Pagination, Thumbs, Autoplay]);

// Swiper
import 'swiper/swiper-bundle.css';

addWindowScrollPositionToDocument();

// Home slider
var swiper = new Swiper('.home-swiper', {
    slidesPerView: 1,
    loop: true,
    pagination: {
        el: '.swiper-pagination',
    },
    navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
    },
    autoplay: {
      delay: 5000,
    },
    slideClass: 'cmeleon-block-content',
});

// Sub content slider (template vendor/cmeleon/core/templates/pages/content2.blade.php)
var swiper = new Swiper('.sub-swiper', {
  slidesPerView: 1,
    loop: true,
    navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
    },
  autoplay: {
    delay: 5000,
  },
    slideClass: 'cmeleon-block-content',
});

// Remove empty slides frontend
const subSwiperCreateBtn = document.querySelectorAll('.sub-swiper .cmeleon-create-block-container');

if (subSwiperCreateBtn.length == 0) {
    const subSwiperSlides = document.querySelectorAll('.sub-swiper .swiper-slide');

    subSwiperSlides.forEach((el) => {
        const hasImg = el.querySelectorAll('img');

        if (hasImg.length == 0) {
            el.parentNode.removeChild(el);
        }
    });
}

// Components slider (template vendor/cmeleon/core/templates/pages/content2.blade.php)
if(window.innerWidth >= 768) {
  const componentsSwiperSlides = document.querySelectorAll('.components-swiper .cmeleon-block-content');



  componentsSwiperSlides.forEach((el) => {
    el.classList.add('swiper-slide');
  });

  var swiperComponents = new Swiper('.components-swiper', {
    slidesPerView: 3,
    loop: false,
    navigation: {
      nextEl: '.swiper-components-button-next',
      prevEl: '.swiper-components-button-prev',
    },
    slideClass: 'cmeleon-block-content',
  });
}

// On click sub nav items, scroll to related heading (template vendor/cmeleon/core/templates/pages/content2.blade.php)
const subNavItems = document.querySelectorAll('.aem-content2-sub-menu ul li');

subNavItems.forEach((el) => el.addEventListener('click', (event) => {
  const subNavItemText = event.target.textContent;
  const relatedHeadingId = subNavItemText.toLowerCase().replaceAll('&', '-').trim().replaceAll(' ', '-');
  const relatedAnchor = document.getElementById(relatedHeadingId);

  console.log(relatedHeadingId);

  const y = (relatedAnchor.getBoundingClientRect().top + window.pageYOffset) - 50;

  window.scrollTo({top: y, behavior: 'smooth'});
}));

// Mobile menu buttons
var mobileToggleBtn = document.getElementById('mobile-toggle-btn');
var mobileCloseBtn = document.getElementById('mobile-close-btn');
var mobileMenu = document.getElementById('mobile-menu');
var mobileMenuFlag = false;

if(mobileToggleBtn){
  mobileToggleBtn.addEventListener('click', function (event) {
    toggleMenu();
  });
}

if(mobileCloseBtn){
  mobileCloseBtn.addEventListener('click', function (event) {
    toggleMenu();
  });
}

function toggleMenu(){
  if(!mobileMenuFlag){
    mobileMenu.classList.remove('hidden');
    mobileMenu.classList.add('block');
    mobileMenuFlag = true;
  }else{
    mobileMenu.classList.remove('block');
    mobileMenu.classList.add('hidden');
    mobileMenuFlag = false;
  }
}

// To top button
var toTopBtn = document.getElementById('to-top-btn');

if(toTopBtn){
  toTopBtn.addEventListener('click', function (event) {
    var topAnchor = document.getElementById('top-anchor');
    topAnchor.scrollIntoView({ behavior: 'smooth' });
  });
}

// Hover for sub menu on desktops
const aemMenuItems = document.querySelectorAll('#main-menu > ul > li');
const aemMenuItemsWithSubs = document.querySelectorAll('.menu-item-with-parent');
const aemMenuItemsWithoutSubs = document.querySelectorAll('#main-menu > ul > li:not(.menu-item-with-parent)');
const subMenus = document.querySelectorAll('.sub-menu');
const lastSubMenuShown = '';

if(aemMenuItemsWithSubs && aemMenuItemsWithoutSubs && subMenus) {
  aemMenuItemsWithSubs.forEach(el => el.addEventListener('mouseover', event => {
    const relatedSubMenu = event.target.parentNode.querySelectorAll('.sub-menu')[0];

    relatedSubMenu.classList.remove('hidden');
    relatedSubMenu.classList.add('block');

    subMenus.forEach(function(el){
      if(relatedSubMenu.id != el.id) {
        el.classList.remove('block');
        el.classList.add('hidden');
      }
    });
  }));

  aemMenuItemsWithoutSubs.forEach(el => el.addEventListener('mouseover', event => {
    subMenus.forEach(function(el){
      el.classList.remove('block');
      el.classList.add('hidden');
    });
  }));
}

// Extra helper functions
function getNodeindex(elm){
  var c = elm.parentNode.children,
    i = c.length;

  while(i--)
    if( c[i] == elm )
      return i
}

// Cookie consent
window.onload = () => {
  if (document.querySelector('[data-cmeleon-cookie-notice]') !== null) {
    const cookieConsent = new CookieConsent(document);
    setTimeout(() => cookieConsent.showNotice(), 2000);
  }

  // Move all img title tags to alt
  const images = document.querySelectorAll('img');
  images.forEach(function(el){
    el.setAttribute('alt', el.getAttribute('title'));
    el.removeAttribute('title');
  });
}
