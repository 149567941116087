import debounce from './debounce';

export default function addWindowScrollPositionToDocument() {
    function setScrollPos() {
        if (window.scrollY > 0) {
            document.documentElement.dataset.scroll = window.scrollY.toString();
        } else {
            delete document.documentElement.dataset.scroll;
        }
    }

    setScrollPos();
    document.addEventListener('scroll', debounce(setScrollPos));
}

export function isInViewport(elem) {
    const bounding = elem.getBoundingClientRect();
    return (
        bounding.top >= 0
        && bounding.left >= 0
        && bounding.bottom <= (window.innerHeight || document.documentElement.clientHeight)
        && bounding.right <= (window.innerWidth || document.documentElement.clientWidth)
    );
}

export function isWindowScrolled() {
    return window.scrollY > 0;
}

export function getWindowScrollPosition() {
    return window.scrollY;
}

export function passesMiddle(elem) {
    const bounding = elem.getBoundingClientRect();
    return bounding.top <= (window.innerHeight || document.documentElement.clientHeight) * 0.5;
}

export function entersViewport(elem) {
    const bounding = elem.getBoundingClientRect();
    return bounding.top <= (window.innerHeight || document.documentElement.clientHeight);
}
