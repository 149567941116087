// Overwritten because IE11
export default class CookieConsent {
    options = {
        wrapper: undefined,
        notice: {
            selector: '[data-cmeleon-cookie-notice]',
            activeClasses: ['translate-y-0', 'md:m-3'],
            inactiveClasses: ['translate-y-full'],
        },
        settings: {
            selector: '[data-cmeleon-cookie-settings]',
            activeClasses: ['translate-x-0'],
            inactiveClasses: ['-translate-x-full'],
        },
    };

    constructor(wrapper) {
        this.options.wrapper = wrapper;
        this.addEventListeners();
    }

    addEventListeners() {
        this.options.wrapper
            .querySelectorAll('[data-cmeleon-cookie-trigger]')
            .forEach(trigger => {
                switch (trigger.getAttribute('data-cmeleon-cookie-trigger')) {
                    case 'show-settings':
                        return trigger.addEventListener('click', () => {
                            return this.hideNotice().then(() => this.showSettings());
                        });
                    case 'accept-all':
                        return trigger.addEventListener('click', () => {
                            return this.acceptAll();
                        });
                }
            });
    }

    element(name) {
        const element = this.options.wrapper.querySelector(this.options[name].selector);

        if (! element) {
            throw this.options[name].selector + ' does not exist.';
        }

        return element;
    }

    show(name) {
        const element = this.element(name);

        element.classList.remove(...this.options[name].inactiveClasses);
        element.classList.add(...this.options[name].activeClasses);

        return element;
    }

    hide(name) {
        const element = this.element(name);

        element.classList.remove(...this.options[name].activeClasses);
        element.classList.add(...this.options[name].inactiveClasses);

        return element;
    }

    showNotice() {
        return new Promise((resolve, reject) => {
            try {
                resolve(this.show('notice'));
            } catch (e) {
                reject(e);
            }
        });
    }

    hideNotice() {
        return new Promise((resolve, reject) => {
            try {
                resolve(this.hide('notice'));
            } catch (e) {
                reject(e);
            }
        });
    }

    showSettings() {
        return new Promise((resolve, reject) => {
            try {
                resolve(this.show('settings'));
            } catch (e) {
                reject(e);
            }
        });
    }

    acceptAll() {
        return new Promise((resolve, reject) => {
            try {
                this.element('settings')
                    .querySelectorAll(`input[type="checkbox"]`)
                    .forEach(c => c.checked = true);

                this.element('settings').querySelector('form').submit();
                resolve();
            } catch (e) {
                reject(e);
            }
        });
    }
}
